import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Box, Divider, IconButton, Toolbar, Typography, TextField, Button, Stack, Grid
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import * as XLSX from 'xlsx';
import {
  useSnackbar
} from "standard";

export default function Batch({ onClose, queryBalance, onSubmit }) {
  const [items, setItems] = useState(null);
  const [available, setAvailable] = useState('');
  const [currency] = useState('CNY');
  const [googleAuthCode, setGoogleAuthCode] = useState(null);
  const [googleAuthCodeError, setGoogleAuthCodeError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    async function func() {
      const availableBalance = await queryBalance('CNY');
      setAvailable(availableBalance);
    }
    func();
  }, [queryBalance]);

  const itemAmountSum = () => {
    if (items == null) {
      return 0;
    }
    return items.map(v => v.amount).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  }

  // 生成 execl 模版文件
  const handleDownloadTemplate = () => {
    // 示例数据
    const data = [
      { 银行卡号: "11111111111111111111", 账户名称: "张三", 银行名称: "中国银行", 金额: 100 },
      { 银行卡号: "22222222222222222222", 账户名称: "李四", 银行名称: "建设银行", 金额: 200 },
    ];

    // 创建工作表
    const ws = XLSX.utils.json_to_sheet(data);

    // 创建工作簿并添加工作表
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // 生成 Excel 文件
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });

    // 创建一个用于下载的链接
    const downloadUrl = window.URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = "批量提款模版.xlsx"; // 文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // 解析 execl 文件
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      if (!Array.isArray(data) || !data.length) {
        return;
      }
      const maxCount = 20;
      if (data.length > maxCount) {
        setSnackbar(`每次最多申请${maxCount}条订单`, 'error');
        return;
      }

      const info = data.map(v => {
        return {
          cardId: v['银行卡号'],
          accountName: v['账户名称'],
          bankName: v['银行名称'],
          amount: v['金额'],
        }
      });
      for (const v of info) {
        if (!v.cardId || !v.accountName || !v.bankName || !v.amount) {
          setSnackbar(`检测到异常数据`, 'error');
          return;
        }
        if (!Number.isInteger(v.amount)) {
          setSnackbar(`检测到金额异常 ${v.amount}, 不能有小数或特殊符号`, 'error');
          return;
        }
      }
      setItems(info);
      setIsLoading(false);
    });
  };

  const handleConfirm = () => {
    if (!googleAuthCode) {
      setGoogleAuthCodeError(true);
      return;
    }
    if (available < itemAmountSum()) {
      setSnackbar(`可用余额不足: 总提款金额(${itemAmountSum()}) > 可用余额(${available})`, 'error');
      return;
    }

    setIsLoading(true);
    async function func() {
      await onSubmit({
        items,
        currency,
        googleAuthCode
      });
      setIsLoading(false)
    }
    func();
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const FileButtons = () => (
    <Stack
      flex={1}
      spacing={1}
      padding={1}
      justifyContent="space-around"
      alignItems="center">
      <Button
        startIcon={<CloudDownloadIcon />}
        variant="outlined"
        color="warning"
        onClick={handleDownloadTemplate}
      >
        下载模版
      </Button>
      <Button
        component="label"
        startIcon={<CloudUploadIcon />}
        variant="outlined"
        color="success"
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      >
        上传文件
        <VisuallyHiddenInput type="file" />
      </Button>
    </Stack>
  )

  const ItemList = () => (
    <Stack
      sx={{
        width: '100%',
        flex: 1,
        maxHeight: '350px', // 设置最大高度
        bgcolor: 'background.paper',
        overflow: 'auto' // 超出部分显示滚动条
      }}
      paddingY={2}
      paddingX={5}
    >
      {items.map(v => (
        <Grid
          key={v}
          container
          justifyContent="space-around"
          alignItems="center">
          <Grid xs={5} border={1}>{v.cardId}</Grid>
          <Grid xs={2} border={1}>{v.accountName}</Grid>
          <Grid xs={3} border={1}>{v.bankName}</Grid>
          <Grid xs={2} border={1}>{v.amount}</Grid>
        </Grid>
      ))}
    </Stack>
  )

  return (
    <Box align='center' width={620}>
      <Toolbar>
        <Box width={50}>
          <div />
        </Box>
        <Typography variant="h4" style={{ flex: 1 }}>
          批量提款
        </Typography>
        <Box width={50}>
          <IconButton edge="end" aria-label="关闭" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />

      <Box minHeight={350} style={{ display: 'flex' }}>
        {items ? <ItemList /> : <FileButtons />}
      </Box>

      <Divider />

      <Stack direction='row' spacing={1} padding={3}>
        <TextField size="small" disabled label='货币' value={'CNY'} />
        <TextField size="small" disabled label='可用余额' value={available} />
        <TextField size="small" disabled label='提款总额' error value={itemAmountSum()} />
        <TextField size="small" label='谷歌验证码' error={googleAuthCodeError} required
          onChange={(event) => {
            setGoogleAuthCode(event.target.value);
          }}
          onFocus={() => {
            setGoogleAuthCodeError(false)
          }}
        />
      </Stack>

      <Button
        sx={{ bottom: 10 }}
        type="submit"
        variant="contained"
        disabled={isLoading}
        onClick={handleConfirm}
      >
        提交
      </Button>
    </Box>
  )
}
Batch.propTypes = {
  queryBalance: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};