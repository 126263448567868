import { serverURL, loginPath } from '../config';

// report
export async function reportReconciliation(data = {}) {
  return get('/report/reconciliation', data);
}

export async function reportMerchant(data = {}) {
  return get('/report/revenue', data);
}

export async function reportMerchantSummary(data = {}) {
  return get('/report/revenueSummary', data);
}

export async function reportInferiorTree() {
  return get('/report/inferiorTree');
}

// secure
export async function gauthVerify(code) {
  return get(`/secure/gauth/${code}`);
}

export async function gauthConfirm(code) {
  return post('/secure/gauth', { code });
}

export async function gauthReset(code) {
  return deleteRequest('/secure/gauth', { code });
}

export async function resetPassword(oldPassword, newPassword) {
  return put('/secure/password', { oldPassword, newPassword });
}

export async function secret(gAuthCode) {
  return get('/secret', { gAuthCode });
}

export async function logList(data = {}) {
  return get('/logs', data);
}

// account
export async function balance(data = {}) {
  return get('/account/availableBalance', data);
}

export async function rateList(data = {}) {
  return get('/account/rate', data);
}

export async function walletList(data = {}) {
  return get('/account/sub', data);
}

export async function statementList(data = {}) {
  return get('/account/history', data);
}

export async function statementExport(data = {}) {
  return listExport('/account/history/export', data);
}


// withdraw
export async function withdrawList(data = {}) {
  return get('/withdraw', data);
}

export async function withdrawSummary(data = {}) {
  return get('/withdraw/summary', data);
}

export async function withdrawAdd(data = {}) {
  return post('/withdraw', data);
}

export async function withdrawBatch(data = {}) {
  return request(`${serverURL}/withdraw/batch`, 'post', data, 'application/json');
}

export async function withdrawExport(data = {}) {
  return listExport('/withdraw/export', data);
}

// deposit
export async function depositList(data = {}) {
  return get('/deposit/order', data);
}

export async function depositSummary(data = {}) {
  return get('/deposit/order/summary', data);
}

export async function depositAdd(data = {}) {
  console.info(data);
  console.info(data.mode);

  return post(`/deposit/mode/${data.mode}`, data);
}

export async function depositExport(data = {}) {
  return listExport('/deposit/order/export', data);
}

export async function chatMessages(id) {
  return get(`/deposit/chat/${id}`);
}

export async function chatImage(id) {
  const imageUrl = `${serverURL}/deposit/image/${id}`;
  return fetch(imageUrl, {
    credentials: 'include',
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('网络请求失败');
      }
      return response.text();
    })
    .catch((error) => {
      console.error('获取图片时出错:', error);
    });
}

export async function listExport(url, data = {}) {
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'GET';
  form.action = `${serverURL}${url}`;
  form.target = '_blank';
  Object.entries(data).forEach(([key, value]) => {
    const input = document.createElement('input');
    input.setAttribute('name', key);
    input.setAttribute('type', 'hidden');
    input.setAttribute('value', value);
    form.appendChild(input);
  });
  form.submit();
  try {
    document.body.removeChild(form);
  } catch {
    console.log('form 不存在!');
  }
}

export async function heartbeat() {
  return get('/heartbeat');
}

export async function me() {
  return get('/me');
}

export async function post(path, data = {}) {
  return request(`${serverURL}${path}`, 'post', data);
}

export async function put(path, data = {}) {
  return request(`${serverURL}${path}`, 'put', data);
}

export async function deleteRequest(path, data = {}) {
  return request(`${serverURL}${path}`, 'delete', data);
}

export async function get(path, data = {}) {
  return request(`${serverURL}${path}`, 'get', data);
}

function toForm(data) {
  const body = new FormData();
  Object.entries(data).forEach(([key, value]) => body.append(key, value))
  return body;
}

async function request(url, method, body, contentType = 'application/x-www-form-urlencoded') {
  let data = null;
  if (body) {
    const queryString = new URLSearchParams(toForm(body)).toString();
    if (method === "get") {
      url = `${url}?${queryString}`
    } else {
      data = contentType === 'application/x-www-form-urlencoded'
        ? queryString
        : JSON.stringify(body);
    }
  }

  return fetch(url, {
    method,
    credentials: 'include',
    headers: {
      "Content-Type": contentType,
    },
    body: data
  })
    .then((response) => {
      if (response.headers.get('Content-Type').indexOf('image') !== -1) {
        return response;
      }
      switch (response.status) {
        case 401:
          console.error(`请重新登录 ${window.location.href}`);
          if (window.location.href.indexOf(loginPath) === -1) {
            window.location.href = loginPath;
          }
          break;
        case 403:
          console.error("没有权限");
          break;
        default:
      }
      const data = response.json();
      if (data.retcode && data.retcode !== 0) {
        if (data.retdesc) {
          console.error(data.retdesc);
        }
      }
      return data;
    })
    .catch((err) => {
      console.error(err.message);
    });;
}

export const modeName = (value) => {
  switch (value) {
    case 'BANK_CARD': return '银行卡转账';
    case 'R_DPAY_U': return '发R存U';
    case 'ETHEREUM': return '以太坊';
    default:
      return value;
  }
}
