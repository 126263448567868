import { useState } from "react";
import {
  Button,
  Stack,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { Amount, StandardSearchGroup, StandardTable, useSnackbar } from "standard";
import { statementList, statementExport } from "../../utils/request";
import { dayEnd, dayStart } from "../../utils/formatTime";

export default function StatementPage() {
  const [queryParams, setQueryParams] = useState({
    timeStart: dayStart(),
    timeEnd: dayEnd(),
  });
  const [version, setVersion] = useState(0);
  const { setSnackbar } = useSnackbar();


  const handleClickExport = () => {
    if (queryParams.timeStart === '' || queryParams.timeEnd === '') {
      setSnackbar("请选择时间区间", 'error');
      return;
    }
    statementExport(queryParams);
  }

  const trxCell = (type) => {
    switch (type) {
      case 'WITHDRAW': return '提款';
      case 'DEPOSIT': return '存款';
      case 'REGULATE': return '调控';
      case 'RECALL': return '撤单';
      default: return '未知';
    }
  }

  return (
    <>
      <StandardSearchGroup
        options={[
          { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart).startOf('day'), } },
          { type: 'date', name: 'timeEnd', label: '结束时间', params: { value: dayjs(queryParams.timeEnd).endOf('day') } },
          {
            type: 'select', name: 'trxType', label: '业务类型', params: { value: queryParams.trxType },
            items: [
              { label: '存款', value: 'DEPOSIT' },
              { label: '提款', value: 'WITHDRAW' },
              { label: '撤单', value: 'RECALL' },
              { label: '调控', value: 'REGULATE' },
            ]
          },
        ]}
        setVersion={setVersion}
        setQueryParams={(v) => {
          setQueryParams({ ...queryParams, ...v })
        }}
      >
        <Stack direction='row' spacing={1} minWidth={250} justifyContent='right'>
          <Button
            startIcon={<DownloadIcon />}
            onClick={handleClickExport}
            color="error"
            variant="outlined"
            style={{ maxHeight: '34px' }}
          >
            导 出
          </Button>
        </Stack>
      </StandardSearchGroup>
      <StandardTable
        columns={[
          { headerName: '创建时间', field: 'createTime', },
          {
            headerName: '金额', field: 'amount',
            renderCell: (params) => <Amount value={params.value} sub={params.row.fundDirection === 'SUB'} />,
          },
          { headerName: '余额', field: 'balance', },
          { headerName: '货币', field: 'currency', },
          { headerName: '请求号', field: 'requestNo', },
          {
            headerName: '业务', field: 'trxType',
            renderCell: (params) => trxCell(params.value),
          },
        ]}
        query={(params) => statementList(params)}
        queryParams={queryParams}
        version={version}
        notFoundSrc={'/no_items_found.png'}
      />
    </>
  );
}