import { useState } from "react";
import {
  Button,
  Popover,
  Stack,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import {
  Amount, StandardSearchGroup, StandardTable, Status, Inspector,
  useBackdrop, useStore, useSnackbar
} from "standard";
import Add from "./Add";
import { depositList, depositExport, modeName, depositSummary, chatMessages } from "../../utils/request";
import { dayEnd, dayStart } from "../../utils/formatTime";
import { Chat } from "./Chat";

const orderIdCell = (id, onClick) => <Button onClick={onClick}>{id}</Button>;

export default function DepositPage() {
  const [queryParams, setQueryParams] = useState({
    timeStart: dayStart(),
    timeEnd: dayEnd(),
    orderId: '',
  });
  const [version, setVersion] = useState(0);
  const [summary, setSummary] = useState(null);

  const { store } = useStore();
  const [add, setAdd] = useState(false);
  const [messages, setMessages] = useState(null);
  const [details, setDetails] = useState(null);
  const { setOpen: setBackdrop } = useBackdrop();
  const { setSnackbar } = useSnackbar();

  const query = async (params) => {
    querySummary(params);
    const result = await depositList(params);
    return {
      list: result.list,
      pagination: {
        total: result.pagination.total
      }
    };
  }

  // 获取聊天消息
  const queryChatMessage = (orderId) => {
    const apply = async () => {
      if (!orderId) {
        return;
      }
      const result = await chatMessages(orderId);
      if (result.url) {
        openExternalURL(result.url);
      } else {
        result.messages.sort((a, b) => a.createTime.localeCompare(b.createTime))
        setMessages(result.messages);
      }
    };
    apply();
  }

  const openExternalURL = (url) => {
    const iWidth = 500; // 弹出窗口的宽度;
    const iHeight = 700; // 弹出窗口的高度;
    const iTop = (window.screen.height - 30 - iHeight) / 2; // 获得窗口的垂直位置;
    const iLeft = (window.screen.width - 10 - iWidth) / 2; // 获得窗口的水平位置;
    const params = `height=${iHeight},innerHeight=${iHeight},width=${iWidth},innerWidth=${iWidth},top=${iTop},left=${iLeft},toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no`;
    window.open(url, "聊天记录", params);
  }

  const querySummary = async (params) => {
    const result = await depositSummary(params);
    setSummary([
      { label: '总金额', value: result.total ?? 0 },
      { label: '总支付', value: result.paid ?? 0 },
      { label: '手续费', value: result.income ?? 0 },
    ]);
  }

  const handleCloseDetails = () => {
    setBackdrop(false);
    setDetails(null);
  }

  const handleClickDetails = (event, row) => {
    setBackdrop(true);
    setDetails(row)
  }

  const handleClickExport = () => {
    if (queryParams.timeStart === '' || queryParams.timeEnd === '') {
      console.info(queryParams);
      setSnackbar("请选择时间区间", 'error');
      return;
    }
    depositExport(queryParams);
  }

  const anchorPosition = { top: window.innerHeight / 6, left: window.innerWidth / 2 };
  const infos = () => {
    return [
      { label: '订单号', value: details.orderId },
      { label: '平台订单号', value: details.id },
      { label: '金额', value: details.amount },
      { label: '手续费', value: details.platIncome },
      { label: '货币', value: details.currency },
      { label: '支付货币', value: details.payCurrency },
      { label: '模式', value: modeName(details.paymode) },
      { label: '状态', value: <Status value={details.status} /> },
      { label: '创建时间', value: details.createTime },
      { label: '完成时间', value: details.completeTime },
    ];
  }
  return <>
    <Popover
      open={Boolean(add)}
      onClose={() => setAdd(false)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Add bankCodes={store.bankCodes} onClose={() => setAdd(false)} />
    </Popover>
    <Popover
      open={Boolean(messages)}
      onClose={() => setMessages(null)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Chat messages={messages} />
    </Popover>
    <Popover
      open={Boolean(details != null)}
      onClose={handleCloseDetails}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {details && <Inspector title={'订单详情'} infos={infos()} />}
    </Popover>
    <StandardSearchGroup
      options={[
        { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart).startOf('day'), } },
        { type: 'date', name: 'timeEnd', label: '结束时间', params: { value: dayjs(queryParams.timeEnd).endOf('day'), } },
        { type: 'text', name: 'orderId', label: '订单号' },
        { type: 'text', name: 'id', label: '平台订单号' },
        {
          type: 'select', name: 'status', label: '状态', params: { value: queryParams.status },
          items: [
            { label: '等待', value: 'WAITING' },
            { label: '成功', value: 'SUCCESS' },
            { label: '失败', value: 'FAILED' },
            { label: '撤单', value: 'RECALL' },
          ]
        },
      ]}
      setVersion={setVersion}
      setQueryParams={(v) => {
        setQueryParams({ ...queryParams, ...v })
      }} >
      <Stack direction='row' spacing={1} minWidth={250} justifyContent='right'>
        <Button
          startIcon={<DownloadIcon />}
          onClick={handleClickExport}
          color="error"
          variant="outlined"
          style={{ maxHeight: '34px' }}
        >
          导 出
        </Button>
        <Button
          startIcon={<AddIcon />}
          onClick={() => setAdd(true)}
          color="error"
          variant="contained"
          style={{ maxHeight: '34px' }}
        >
          存 款
        </Button>
      </Stack>
    </StandardSearchGroup>
    <StandardTable
      columns={[
        {
          headerName: '状态', field: 'status',
          renderCell: (params) => <Status value={params.value} />,
        },
        {
          headerName: '订单号', field: 'orderId',
          renderCell: (params) => orderIdCell(
            params.value,
            (event) => handleClickDetails(event, params.row),
          ),
        },
        {
          headerName: '支付模式', field: 'paymode',
          renderCell: (params) => (
            <Button onClick={() => queryChatMessage(params.row.id)}>
              {modeName(params.value)}
            </Button>
          ),
        },
        {
          headerName: '金额', field: 'amount', type: 'number',
          renderCell: (params) => <Amount value={params.value} />,
        },
        { headerName: '货币', field: 'currency', },
        { headerName: '创建时间', field: 'createTime', },
        { headerName: '完成时间', field: 'completeTime', },
      ]}
      query={query}
      summary={summary}
      queryParams={queryParams}
      version={version}
      notFoundSrc='/no_items_found.png'
    />
  </>
}