import { useState } from "react";
import dayjs from "dayjs";
import { Amount, StandardSearchGroup, StandardTable } from "standard";
import { reportReconciliation } from "../../utils/request";
import { dayEnd, dayStart } from "../../utils/formatTime";

export default function ReconciliationPage() {
  const [queryParams, setQueryParams] = useState({
    timeStart: dayStart(),
    timeEnd: dayEnd(),
  });
  const [version, setVersion] = useState(0);

  return (
    <>
      <StandardSearchGroup
        options={[
          { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart).startOf('day'), } },
          { type: 'date', name: 'endStart', label: '结束时间', params: { value: dayjs(queryParams.timeEnd).endOf('day') } },
        ]}
        setVersion={setVersion}
        setQueryParams={(v) => {
          setQueryParams({ ...queryParams, ...v })
        }} />
      <StandardTable
        columns={[
          { headerName: '日期', field: 'createTime', },
          { headerName: '货币', field: 'currency', },
          { headerName: '开始余额', field: 'balanceStart', },
          {
            headerName: '存款金额', field: 'depositSum',
            renderCell: (params) => <Amount value={params.value} />
          },
          {
            headerName: '提款金额', field: 'withdrawSum',
            renderCell: (params) => <Amount value={params.value} />
          },
          {
            headerName: '调整', field: 'regulate',
            renderCell: (params) => <Amount value={params.value} />
          },
          { headerName: '误差', field: 'deviation', },
          { headerName: '结算余额', field: 'balance', },
          // {
          //   headerName: '利润', field: 'profit',
          //   renderCell: (params) => <Amount value={params.value} />,
          // },
        ]}
        getRowId={(row) => `${row.createTime}${row.currency}`}
        query={reportReconciliation}
        queryParams={queryParams}
        version={version}
      />
    </>
  );
}
