import { useState } from "react";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import {
  Card,
  Button,
  Stack,
  TextField,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "standard";
import { depositAdd } from "../../utils/request";
import useResponsive from "../../hooks/useResponsive";

export default function Add({ onClose }) {
  const isDesktop = useResponsive('up', 'lg');
  const { register, handleSubmit } = useForm();
  const { setSnackbar } = useSnackbar();
  const [mode, setMode] = useState('OTC');
  const [currency, setCurrency] = useState('CNY');

  const handleConfirm = (data) => {
    async function add() {
      const result = await depositAdd(data);
      if (result && result.retcode === 0) {
        setSnackbar('提交成功', 'success');
        window.open(result.data, '_blank');
      } else {
        setSnackbar(result.retdesc, 'error');
      }
    }
    add();
    onClose();
  }

  // 检测当前屏幕宽度
  return (
    <Card align='center' sx={{ minWidth: isDesktop ? 400 : '100%' }}>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="8vh" fontSize={20}>
        申请存款
      </Box>
      <Divider />
      <form onSubmit={handleSubmit(handleConfirm)}>
        <Stack padding={4} spacing={2}>
          <AddField name='amount' label='金额' register={register} />
          <AddField name='payerName' label='支付人姓名' register={register} />
          <FormControl variant="filled">
            <InputLabel >货币</InputLabel>
            <Select
              size="small"
              {...register("currency")}
              value={currency}
              align="left"
              required
              onChange={(event) => { setCurrency(event.target.value) }}
            >
              <MenuItem value='CNY'>人民币</MenuItem>
              <MenuItem value='USDT'>USDT</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="filled">
            <InputLabel >支付模式</InputLabel>
            <Select
              size="small"
              {...register("mode")}
              value={mode}
              align="left"
              required
              onChange={(event) => { setMode(event.target.value) }}
            >
              <MenuItem value='OTC'>OTC</MenuItem>
              <MenuItem value='BANK_CARD'>银行卡转账</MenuItem>
              <MenuItem value='ALIPAY_QR'>支付宝</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Button type="submit" sx={{ bottom: 10 }} variant="contained">
          提交
        </Button>
      </form>
    </Card>);
}
Add.propTypes = {
  onClose: PropTypes.func,
};


function AddField({ name, label, value, register }) {
  return (
    <TextField
      {...register(name)}
      required
      size="small"
      label={label}
      value={value}
    />
  );
}
AddField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  register: PropTypes.func,
};