import { useState } from "react";
import {
  Button,
  Popover,
  Stack,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
  Amount, StandardSearchGroup, StandardTable, WithdrawForm, Status, Inspector,
  useBackdrop, useStore, useSnackbar
} from "standard";
import { withdrawList, withdrawExport, withdrawSummary, balance, withdrawAdd, withdrawBatch } from "../../utils/request";
import { dayEnd, dayStart } from "../../utils/formatTime";
import Batch from "./Batch";

const orderIdCell = (id, onClick) => <Button onClick={onClick}>{id}</Button>;

export default function WithdrawPage() {
  const [queryParams, setQueryParams] = useState({
    timeStart: dayStart(),
    timeEnd: dayEnd(),
    orderId: '',
  });
  const [version, setVersion] = useState(0);
  const [summary, setSummary] = useState(null);
  const [opPopover, setOpPopover] = useState(null);

  const { store } = useStore();
  const [add, setAdd] = useState(false);
  const [batch, setBatch] = useState(false);
  const [details, setDetails] = useState(null);
  const { setOpen: setBackdrop } = useBackdrop();
  const { setSnackbar } = useSnackbar();

  const handleOpPopoverClose = () => {
    setOpPopover(null);
  };

  const query = async (params) => {
    querySummary(params);
    const result = await withdrawList(params);
    return {
      list: result.list,
      pagination: {
        total: result.pagination.total
      }
    };
  }

  const querySummary = async (params) => {
    const result = await withdrawSummary(params);
    setSummary([
      { label: '总金额', value: result.total ?? 0 },
      { label: '总支付', value: result.paid ?? 0 },
      { label: '手续费', value: result.income ?? 0 },
    ]);
  }

  const handleWithdrawSubmit = (data) => {
    if (!data.bankCode) {
      setSnackbar('请选择提款银行', 'error');
      return;
    }
    async function withdraw() {
      const result = await withdrawAdd({
        cardId: data.cardId,
        accountName: data.accountName,
        bankName: data.bankName,
        bankCode: data.bankCode,
        amount: data.amount,
        currency: data.currency,
        googleAuthCode: data.googleAuthCode,
      });
      if (result && result.retcode === 0) {
        setSnackbar('提交成功', 'success');
        setVersion(v => v + 1);
      } else {
        setSnackbar(result.retdesc, 'error');
      }
    }
    withdraw();
    setAdd(false);
  }

  const handleBatchSubmit = (data) => {
    async function withdraw() {
      const result = await withdrawBatch(data);
      if (result && result.retcode === 0) {
        setSnackbar('提交成功', 'success');
        setVersion(v => v + 1);
      } else {
        setSnackbar(result.retdesc, 'error');
      }
    }
    withdraw();
    setBatch(false);
  }

  const queryBalance = async (currency) => {
    const result = await balance({ currency });
    return result.availableBalance;
  }

  const handleCloseDetails = () => {
    setBackdrop(false);
    setDetails(null);
  }

  const handleClickDetails = (event, row) => {
    setBackdrop(true);
    setDetails(row)
  }

  const handleClickExport = () => {
    if (queryParams.timeStart === '' || queryParams.timeEnd === '') {
      setSnackbar("请选择时间区间", 'error');
      return;
    }
    withdrawExport(queryParams);
  }

  const anchorPosition = { top: 60, left: window.innerWidth / 2 };
  const infos = () => [
    { label: '订单号', value: details.orderId },
    { label: '平台订单号', value: details.id },
    { label: '金额', value: details.amount },
    { label: '卡号', value: details.cardId },
    { label: '姓名', value: details.accountName },
    { label: '银行', value: details.bankName },
    { label: '创建时间', value: details.createTime },
    { label: '完成时间', value: details.completeTime },
    { label: '状态', value: <Status value={details.status} /> },
  ];

  return <>
    <Popover
      open={Boolean(add)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <WithdrawForm
        bankCodes={store.bankCodes}
        onClose={() => setAdd(false)}
        onSubmit={handleWithdrawSubmit}
        queryBalance={queryBalance}
      />
    </Popover>
    <Popover
      open={Boolean(batch)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Batch
        onClose={() => setBatch(false)}
        queryBalance={queryBalance}
        onSubmit={handleBatchSubmit}
      />
    </Popover>
    <Popover
      open={Boolean(details != null)}
      onClose={handleCloseDetails}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {details && <Inspector title={'订单详情'} infos={infos()} />}
    </Popover>
    <StandardSearchGroup
      options={[
        { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart).startOf('day'), } },
        { type: 'date', name: 'timeEnd', label: '结束时间', params: { value: dayjs(queryParams.timeEnd).endOf('day'), } },
        { type: 'text', name: 'orderId', label: '订单号' },
        { type: 'text', name: 'id', label: '平台订单号' },
        { type: 'text', name: 'cardId', label: '卡号或姓名' },
        {
          type: 'select', name: 'status', label: '状态', params: { value: queryParams.status },
          items: [
            { label: '等待', value: 'WAITING' },
            { label: '处理中', value: 'ASSIGN' },
            { label: '成功', value: 'SUCCESS' },
            { label: '失败', value: 'FAILED' },
            { label: '撤单', value: 'RECALL' },
          ]
        },
      ]}
      setVersion={setVersion}
      setQueryParams={(v) => {
        setQueryParams({ ...queryParams, ...v })
      }} >
      <Popover
        open={Boolean(opPopover)}
        anchorEl={opPopover}
        onClose={handleOpPopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 120,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }
        }}
      >
        <Stack alignContent='center' sx={{ p: 1 }}>
          {/* <Button
            startIcon={<PlaylistAddIcon />}
            onClick={() => {
              setBatch(true);
              handleOpPopoverClose();
            }}
            color="error"
          >
            批量提款
          </Button> */}
          <Button
            startIcon={<DownloadIcon />}
            onClick={() => {
              handleClickExport();
              handleOpPopoverClose();
            }}
          >
            数据导出
          </Button>
        </Stack>

      </Popover>
      <Stack
        direction='row'
        spacing={1}
        minWidth={220}
        justifyContent='right'>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            setAdd(true);
            handleOpPopoverClose();
          }}
          color="error"
        >
          提款
        </Button>
        <Button
          startIcon={<FormatListBulletedIcon />}
          color="error"
          variant="outlined"
          onClick={(event) => {
            setOpPopover(event.currentTarget);
          }}
        >
          {'操作'}
        </Button>
      </Stack>
    </StandardSearchGroup>
    <StandardTable
      columns={[
        {
          headerName: '状态', field: 'status', width: 120,
          renderCell: (params) => <Status value={params.value} />,
        },
        {
          headerName: '订单号', field: 'orderId',
          renderCell: (params) => orderIdCell(
            params.value,
            (event) => handleClickDetails(event, params.row),
          ),
        },
        { headerName: '货币', field: 'currency', width: 70 },
        { headerName: '账户名', field: 'accountName', },
        { headerName: '卡号', field: 'cardId', },
        { headerName: '银行', field: 'bankName', },
        {
          headerName: '金额', field: 'amount', type: 'number', width: 130,
          renderCell: (params) => <Amount value={params.value} sub />,
        },
        { headerName: '创建时间', field: 'createTime', width: 170, },
      ]}
      query={query}
      summary={summary}
      queryParams={queryParams}
      version={version}
      notFoundSrc={`/no_items_found.png`}
    />
  </>
}