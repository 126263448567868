import { useState } from "react";
import dayjs from "dayjs";
import { StandardSearchGroup, StandardTable, Amount } from "standard";
import { reportMerchant, reportMerchantSummary } from "../../utils/request";

export default function RevenuePage() {
  const day = dayjs().startOf('day').format("YYYY-MM-DD");
  const [queryParams, setQueryParams] = useState({
    timeStart: day,
    timeEnd: day,
    inferior: '',
  });
  const [version, setVersion] = useState(0);
  const [summary, setSummary] = useState({});

  const query = async (params) => {
    querySummary(params)
    const result = await reportMerchant(params);
    return {
      list: result.list,
      pagination: {
        total: result.length
      }
    };
  }

  const querySummary = async (params) => {
    const info = await reportMerchantSummary(params);
    if (info) {
      setSummary(info)
    }
  }

  return <>
    <>
      <StandardSearchGroup
        options={[
          { type: 'date', name: 'timeStart', label: '开始时间', params: { value: dayjs(queryParams.timeStart).startOf('day'), } },
          { type: 'date', name: 'timeEnd', label: '结束时间', params: { value: dayjs(queryParams.timeEnd).endOf('day') } },
        ]}
        setVersion={setVersion}
        setQueryParams={(v) => {
          setQueryParams({ ...queryParams, ...v })
        }} />
      <StandardTable
        columns={[
          { headerName: '日期', field: 'createTime', },
          { headerName: '货币', field: 'currency', },
          {
            headerName: '存款总额', field: 'depositSum',
            renderCell: (params) => <Amount value={params.value} />
          },
          {
            headerName: '存款费用', field: 'depositCost',
            renderCell: (params) => <Amount value={params.value} sub />
          },
          {
            headerName: '提款总额', field: 'withdrawSum',
            renderCell: (params) => <Amount value={params.value} sub />
          },
          {
            headerName: '提款费用', field: 'withdrawCost',
            renderCell: (params) => <Amount value={params.value} sub />
          },
        ]}
        getRowId={(row) => `${row.createTime}${row.currency}`}
        query={query}
        queryParams={queryParams}
        version={version}
        notFoundSrc='/no_items_found.png'
        summary={[
          { label: '存款总额', value: <span style={{ color: 'red' }}>{summary.dsum}</span> },
          { label: '存款费用', value: <span style={{ color: 'red' }}>{summary.dcost}</span> },
          { label: '提款总额', value: <span style={{ color: 'red' }}>{summary.wsum}</span> },
          { label: '提款费用', value: <span style={{ color: 'red' }}>{summary.wcost}</span> },
        ]}
      />
    </>
  </>
}