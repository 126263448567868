import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Stack, Avatar, Button, Popover } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PasswordIcon from '@mui/icons-material/Password';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import KeyIcon from '@mui/icons-material/Key';
import {
  ResetPassword, GoogleAuthStepper,
  useSnackbar, useStore
} from "standard";
import account from '../../../_mock/account';
import { me, post, resetPassword, gauthReset, gauthConfirm, gauthVerify } from '../../../utils/request';
import { loginPath } from '../../../config';
import SecretKey from './SecretKey';

export default function AccountPopover() {
  const { setStore } = useStore();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const [open, setOpen] = useState(null);
  const [name, setName] = useState(null);
  const [googleStep, setGoogleStep] = useState(-1);
  const [pwd, setPwd] = useState(false);
  const [secret, setSecret] = useState(false);

  useEffect(() => {
    async function fetchMe() {
      // 账户信息
      const data = await me();
      data.modes = JSON.parse(data.modes);
      data.bankCodes = JSON.parse(data.bankCodes);
      setStore(data);
      setName(data.name);
      if (data.gauth) {
        setGoogleStep(1);
      }
    }
    fetchMe();
  }, []);

  const handleResetPassword = async ({ oldPwd, newPwd }) => {
    const result = await resetPassword(oldPwd, newPwd);
    if (result && result.retcode === 0) {
      setSnackbar('修改密码成功', 'success');
    } else {
      setSnackbar(result.retdesc, 'error');
    }
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    post('/logout');
    navigate(loginPath, { replace: true });
  };

  return (
    <>
      <Stack direction='row' spacing={2}>
        <Button
          style={{ textTransform: 'none' }}
          endIcon={<Avatar src={`${account.photoURL}`} alt="photoURL" />}
          label={name}
          onClick={(event) => {
            setOpen(event.currentTarget);
          }}
        >
          {name}
        </Button>
      </Stack>
      <GoogleAuthStepper
        verify={gauthVerify}
        confirm={gauthConfirm}
        reset={gauthReset}
        open={googleStep >= 0}
        initStep={googleStep}
        onClose={() => setGoogleStep(-1)}
      />
      <ResetPassword
        submit={handleResetPassword}
        open={pwd}
        onClose={() => setPwd(false)} />
      <SecretKey open={secret} onClose={() => setSecret(false)} />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 160,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }
        }}
      >
        <Stack alignContent='center' sx={{ p: 1 }}>
          <Button color='inherit'
            onClick={() => {
              setPwd(true);
              handleClose();
            }} startIcon={<PasswordIcon />}>
            修改密码
          </Button>
          <Button color='inherit'
            onClick={() => {
              setSecret(true);
              handleClose();
            }} startIcon={<KeyIcon />}>
            获取密钥
          </Button>
          <Button color='inherit' onClick={() => {
            setGoogleStep(0);
            handleClose();
          }} startIcon={<PhonelinkLockIcon />}>
            谷歌验证
          </Button>
          <Divider sx={{ borderStyle: 'dashed', margin: 1 }} />
          <Button color='error' onClick={handleLogout} startIcon={<LogoutIcon />}>
            退出登录
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
