import { useState, useEffect } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, styled,
  Box, Stepper, Step, StepLabel, Button,
} from '@mui/material';
import { useSnackbar } from "standard";
import { secret } from '../../../utils/request';

const steps = ['验证', '密钥'];

export default function SecretKey({ open, onClose }) {
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => setActiveStep(0), [])

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const stepOperation = (step) => {
    switch (step) {
      case 0:
        return <InputOldCode
          onClose={onClose}
          onConfirm={(v) => {
            setData(v);
            handleNext();
          }}
        />;
      default:
        return <Success mchCode={data.key} mchKey={data.secret} onClose={onClose} />;
    }
  }

  return (
    <Dialog open={open}>
      <Grid sx={{ margin: 3 }}>
        <Stepper sx={{ width: '100%' }} activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Grid container direction='column' sx={{
          minHeight: 233,
          minWidth: 350,
        }}>
          {stepOperation(activeStep)}
        </Grid>
      </Grid>
    </Dialog>
  );
}

// 第一步，验证谷歌验证码
function InputOldCode({ onClose, onConfirm }) {
  const [code, setCode] = useState();
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    if (!code) {
      setError('请输入谷歌验证码');
    } else {
      const verify = async () => {
        const result = await secret(code);
        if (result && result.secret) {
          onConfirm(result);
        } else {
          setError('谷歌验证错误');
        }
      }
      verify();
    }
  }

  return (
    <Grid container direction='column' >
      <Grid item>
        <DialogTitle color='red' align='center'>
          请将输入已绑定的谷歌验证码
        </DialogTitle>
      </Grid>
      <Grid item>
        <DialogContent >
          <TextField
            size='small'
            required
            autoFocus
            fullWidth
            error={error}
            margin="dense"
            label={error || "谷歌验证码"}
            type="number"
            onFocus={() => setError(null)}
            onChange={(event) => {
              setCode(event.target.value);
            }}
          />
        </DialogContent>
      </Grid>
      <Grid item>
        <DialogActions>
          <Button onClick={onClose} autoFocus>取消</Button>
          <Button onClick={handleSubmit} autoFocus>确认</Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
}

// 第二步，显示成功
function Success({ mchCode, mchKey, onClose }) {
  return (
    <Grid
      direction="column"
      container
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid container
        direction='column'
        spacing={3}
        sx={{ mt: 4, mb: 1, height: 150, width: 500 }}>
        <Pair name='商户码' value={mchCode} />
        <Pair name='密钥' value={mchKey} />
      </Grid>
      <Grid>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={onClose}>结束</Button>
        </Box>
      </Grid>
    </Grid>
  );
}

const Item = styled(Grid)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  alignItems: "center",
  color: theme.palette.text.secondary,
}));

function Pair({ name, value }) {
  const { setSnackbar } = useSnackbar();
  const handleClick = () => {
    setSnackbar(`已复制：${name}`);
    navigator.clipboard.writeText(value);
  };

  return (
    <Grid container item xs={6}>
      <Grid container item alignItems='center' xs={4} sx={{ backgroundColor: '#6b686880', border: 1 }}>
        <Item >{name}</Item>
      </Grid>
      <Grid container item alignItems='center' xs={8} sx={{ border: 1 }}>
        <Item>
          <Button size='small' color="success" onClick={handleClick}>
            {value}
          </Button>
        </Item>
      </Grid>
    </Grid>);
}


