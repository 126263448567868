import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, Box, Typography } from "@mui/material";
import { MessageBox } from 'react-chat-elements';
import "react-chat-elements/dist/main.css"
import { chatImage } from "../../utils/request";
import "../../App.css"

Chat.propTypes = {
  orderId: PropTypes.string,
};

export function Chat({ messages = [] }) {
  if (!messages) {
    messages = [];
  }
  return (
    <Box sx={{
      height: 650,
      width: 500,
    }}>
      <Typography
        textAlign='center'
        alignItems='center'
        fontSize={20}
        sx={{
          padding: 1,
          boxShadow: 0
        }}>
        聊天记录
      </Typography>
      <Divider />
      {messages.map(v => <ChatMessage key={v.id} {...v} />)}
    </Box>
  );
}

function ChatMessage({ id, talk, type, content, createTime, creater }) {
  const [image, setImage] = useState();
  // 获取聊天消息
  const query = () => {
    const q = async () => {
      if (type === 'TYPE_IMAGE') {
        const data = await chatImage(id, setImage);
        setImage(data);
      }
    }
    q();
  }

  useEffect(query, [id]);

  const position = creater === talk ? 'left' : 'right';
  let text = '';
  if (type === 'TYPE_BANKCARD') {
    const bankCard = JSON.parse(content);
    text = <BankCardMessage
      amount={bankCard.amount}
      accountName={bankCard.accountName}
      cardId={bankCard.cardId}
      bankName={bankCard.bankName}
    />;
  } else if (type === 'TYPE_TEXT') {
    text = content;
  } else {
    text = <img src={image} alt="图片" width="250" height="150" />
  }

  return <MessageBox
    styles={{ whiteSpace: 'pre-wrap', backgroundColor: '#CAFF70' }}
    position={position}
    type={'text'}
    text={text}
    date={createTime}
    dateString={createTime}
  />
}


const BankCardMessage = ({ amount, accountName, cardId, bankName }) => {
  return (<div style={{ width: 220, height: 130, padding: 0, backgroundColor: '#A9A9A9', color: 'white', borderRadius: 7, paddingBlock: 10, fontSize: 10 }}>
    <div style={{ backgroundColor: 'black', width: "100%", height: 30 }}>
      <span
        className="bankcard_top_title"
        style={{ padding: 10, lineHeight: "30px", fontSize: 16 }}
      >
        <b>银行卡</b>
      </span>
      <img
        style={{ width: 30, borderRadius: 15, right: 0, float: 'right', marginRight: 5, marginTop: 0 }}
        className="bankcard_top_img"
        src='photo_backOTC.jpg'
        alt="Bank"
      />
    </div>
    <div style={{ padding: "5px 10px 10px 10px" }}>
      <div className="bankcard_center_text">
        金额：{amount}
      </div>
      <div className="">
        开户姓名：{accountName}
      </div>
      <div className="bankcard_center_text">
        卡号：{cardId}
      </div>
      <div className="bankcard_center_text">
        开户行：{bankName}
      </div>
    </div>
  </div>)
};
